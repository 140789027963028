.footer {
  width: 100%;
  text-align: center;
  margin: 0;
  height: auto;
  background: #6d5b5b;
  border-top: 3mm ridge rgb(207, 115, 45);
  align-self: flex-end;
}

.socialIcon {
  color: #272727;
  margin: 0 12px;
}

.socialIcon:hover {
  color: #ffaa00;
}

.socialContainer {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  margin: 0 auto;
}

.footerWave {
  height: 2rem;
  object-position: -20rem;
}

.copyright {
  margin-top: .5em;
  padding-bottom: 1em;
  font-size: .8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #272727;
}
