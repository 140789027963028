.graphContainer {
  padding-top: 40px;
  margin-bottom: 16px;
}

.dataPageHeader {
  padding: 10px 20px;
  background-color: #f2f2f2;
}

.dataContainer {
  padding: 24px 24px 40px;
}

@media only screen and (max-width: 600px) {
  .dataContainer {
    padding: 24px 0px 40px;
  }
}
