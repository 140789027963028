.verticalSegmentLabel {
  writing-mode: tb-rl;
  z-index: 1;
  font-weight: 600;
  fill: gray;
}

.recharts-label {
  font-weight: 500;
  fill: black;
  color: #deaa30;
}

.recharts-line {
  mix-blend-mode: multiply;
}

@media only screen and (max-width: 600px) {
  .lineChart {
    font-size: 0.8rem;
  }
}