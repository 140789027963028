.uploadForm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.uploadCard {
  padding: 30px 30px 20px;
  border-radius: 6px;
  box-shadow: 5px 10px 10px hsl(0deg 0% 0% / 0.34);
  margin: 50px 20px 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  text-align: center;
  background: rgba(255, 170, 0, 0.719);
}

.uploadInstructions {
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 1.1rem;
  padding-right: 1rem;
  font-family: 'Roboto', sans-serif;
}

.uploadNote {
  margin: 20px 0 0;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
}

.uploadButtonsContainer {
  display: flex;
  flex-direction: column;
  width: 250px;
  align-self: center;
}

.chooseFileButtons {
  margin-bottom: 10px;
  display: flex;
}

.instructionsLink {
  font-weight: 700;
}

.bannerActionContainer {
  display: flex;
}

.dismissActionButton {
  margin-right: 16px !important;
}

@media only screen and (max-width: 920px) {
  .bannerActionContainer {
    align-items: flex-end;
    flex-direction: column;
  }

  .dismissActionButton {
    margin-right: 0 !important;
    margin-bottom: 8px !important;
    min-width: 90px;
  }

  .demoActionButton {
    min-width: 90px;
  }
}
