.selectContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.labelContainer {
  display: flex;
  align-items: flex-end;
}