.tiptap {
  background-color: white;
  padding: 12px 16px;  
  color: #32383E;
}

.tiptap ul {
margin: 6px 0;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.ProseMirror:focus {
  outline: 2px solid #ffaa00;
  outline-offset: 2px;
}